import React, { Component } from "react"
import styled from "styled-components"
import Slider from "react-slick"
import { v4 } from "uuid"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { ArrowLeftIcon, ArrowRightIcon } from "../../components/Icons"
import RelatedProductCard from "../../components/RelatedProductCard"
import BreakpointUp from "../../components/Media/BreakpointUp"
import BreakpointDown from "../../components/Media/BreakpointDown"

const SlickItem = styled.div`
  display: block !important;
  outline: none;
  max-width: 100%;
  /* .card-title{
    ${BreakpointDown.lg`
      color:#fff;
    `}
  } */
`
const SlickSlider = styled.div`
  padding: 0;
`
const SlickArrowTrack = styled.div`
  position: absolute;
  top: calc(50% - 45px);
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  right: 0;
  min-height: 30px;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`
const LeftArrow = styled.div`
  position: absolute;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  left: -10px;
  ${BreakpointUp.lg`
    left:-18px;
  `}
  svg {
    fill: #fc0002;
  }
  &:hover {
    background-color: #0b619b;
    svg {
      fill: #fff;
    }
  }
`
const RightArrow = styled.div`
  position: absolute;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  right: -10px;
  ${BreakpointUp.lg`
    right:-18px;
  `}
  svg {
    fill: #fc0002;
  }
  &:hover {
    background-color: #0b619b;
    svg {
      fill: #fff;
    }
  }
`

export default class RelatedProductCarousel extends Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }
  next() {
    this.slider.slickNext()
  }
  previous() {
    this.slider.slickPrev()
  }
  render() {
    const { data } = this.props
    const settings = {
      infinite: true,
      slidesToShow: 1,
      speed: 500,
      arrows: false,
    }
    return (
      <SlickSlider>
        <Slider {...settings} ref={c => (this.slider = c)}>
          {data.map(item => (
            <SlickItem key={v4()}>
              <RelatedProductCard data={item.node} />
            </SlickItem>
          ))}
        </Slider>

        <SlickArrowTrack>
          <LeftArrow onClick={this.previous}>
            <ArrowLeftIcon />
          </LeftArrow>
          <RightArrow onClick={this.next}>
            <ArrowRightIcon />
          </RightArrow>
        </SlickArrowTrack>
      </SlickSlider>
    )
  }
}
